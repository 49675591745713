import React, { useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from "next/link";
import Image from "next/image";
import { Assets } from "../list/Assets";
import styles from '../../styles/HorizontalScroll.module.css';
import SkeletonLoader from '../../src/components/SkeletonLoader';

const Gridcom = dynamic(() => import('../components/Gridcom'), { });



const HorizontalScroll = ({ games, category, viewMoreUrl, layoutType, showhot}) => {

  const [imageLoaded, setImageLoaded] = useState(false);

  const myImageLoader = ({ src }) => {
    return `${src}?w=100&q=100`;
  };
  const myImageLoaderNew = ({}) => {
    return `${Assets[0].new_l}?w=${100}&q=${100}`;
  };

  function newLabel(value) {
    if (value <= 4) {
      return (
        <div className= {styles.new_badge}>
          <Image
            loader={myImageLoaderNew}
            src={"image-src"}
            alt={"new"}
            layout="fill"
            loading="lazy"
          />
        </div>
      );
    }
    return null;
  }


  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const isLessThan30 = games.length < 30; // Check if the game count is less than 30

    // Conditional className assignment based on layoutType
  const wrapperClassName = layoutType === 'double' ? styles.grid_container : styles.grid_container2;

  const Hot_thumb = showhot === 'yes';

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (

    <>
       <div className={styles.header}>
        
        <div className={styles.header_container}><h2>{category} </h2>
        {viewMoreUrl && (
          <Link href={viewMoreUrl}>
          <a  className={styles.viewMoreButton}>
            View More
          </a>
          </Link>
        )}</div>
        <div className={styles.header_container2}>
        <div onClick={scrollLeft} className={styles.scrollButton}>◀</div>
        <div onClick={scrollRight} className={styles.scrollButton}>▶</div>
        </div>
        
      </div>
     
      <div className={styles.game_section} ref={scrollContainerRef}>
      
        <div className= {wrapperClassName}>
          {games.map((game, index) => {
            // Determine the correct image source
           
            const imageUrl = game.hot === '2x2' && Hot_thumb? game.thumb_big : game.hot === '1x1' ? game.thumb : game.thumb;
            const Hotclass = game.hot === '2x2' && Hot_thumb ? `${styles.thumb_1x1} ${styles.thumb_2x2}`: styles.thumb_1x1;
            return (
              <Link key={index} href={game.path[0]} prefetch={false}>
             <a className={Hotclass} onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}  > 
               <div className={styles.thumb_container}>
                 <span className={styles.title}>{game.name[0]}</span>
                 <div className={styles.cover}/>
                 <div className={styles.thumb_img} style={{ width: '100%', height: '100%', position: 'relative' }}>
              

                      {/* Video overlay */}
                      {hoveredIndex === index && game.video ? (
                        <video
                          src={game.video}
                          autoPlay
                          muted
                          loop
                          className={styles.gameVideo}
                          // style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : null}
                      {/* Image */}
                      
                      {!imageLoaded && <SkeletonLoader />}
                      
                    <div
              className={styles.imageWrapper}
              style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}
            >
              <Image
                loader={myImageLoader}
                src={imageUrl}
                alt={game.name[0]}
                layout="fill"
                loading="lazy"
                className={hoveredIndex === index && game.video ? styles.imageOverlay : ''}
                onLoad={() => setImageLoaded(true)}
              />
            </div>
                 </div>
               </div>
               {/* {newLabel(index)} */}
             </a>
           </Link>
            );
          })}
        </div>
      </div>
      </>
  );
};

export default HorizontalScroll;
